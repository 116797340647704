.breadcrumb_main {
  border-top: 1px solid #ddd;
  padding: 20px 0px;
}
.login {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 50px;
  img {
    width: 100% !important;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #000;
  }
  input {
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    border-radius: 0px;
    height: 45px;
    color: #000;
  }
  button {
    background-color: #ff1933;
    color: #fff;
    width: 100%;
    line-height: 40px;
    border: 1px solid #ff1933;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    padding-top: 10px;
    color: #000;
    a {
      color: #ff1933;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .d_none_mobile {
    display: none !important;
  }
  .login {
    h2 {
      text-align: center;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      padding-top: 10px;
      color: #000;
      a {
        color: #ff1933;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}
